import React from 'react';
import emailjs from 'emailjs-com';
import '../css/contact.css';
import { Link } from 'react-router-dom';

const ContactForm = () => {
  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target, 'uzXz7w9Rlh0ILXB69')
      .then((result) => {
        document.getElementById('form-success').style.display = 'block';
        document.getElementById('form-error').style.display = 'none';
      }, (error) => {
        document.getElementById('form-success').style.display = 'none';
        document.getElementById('form-error').style.display = 'block';
      });
  };

  return (
    <div className="contact-container">
      <img src="/images/random/stones.jpg" alt="Decorative stones" />
      <h1>Contact Us Today!</h1>
      <div className="sub-contact">
        <img src="/images/homepage/droplet.png" alt="droplet" />
        <h1>We'd Love to Hear from You!</h1>
      </div>
      <form name="contact-form" onSubmit={sendEmail}>
        <p><strong>"*"</strong> indicates required fields</p>
        <p>
          <label>Name* <input type="text" name="name" required /></label>
        </p>
        <p>
          <label>Email* <input type="email" name="email" required /></label>
        </p>
        <p>
          <label>Phone <input type="tel" name="phone" /></label>
        </p>
        <p>
          <label>Location <input type="text" name="location" /></label>
        </p>
        <p>
          <label>Message <textarea name="message"></textarea></label>
        </p>
        <p>
          <div className="homepage-buttons">
            <Link to="https://booking.hydreight.com/widget">
              <button className="view-services">SUBMIT</button>
            </Link>
          </div>
        </p>
        <div id="form-success" style={{display: 'none', color: 'green'}}>Thank you for your submission!</div>
        <div id="form-error" style={{display: 'none', color: 'red'}}>Oops! Something went wrong.</div>
      </form>
    </div>
  );
};

export default ContactForm;